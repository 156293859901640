<template>
  <div class="end-page-wrapper">
    <dv-loading class="loading">
      <img class="bg" src="@/assets/image/bg.jpg" />
      <div class="loading-text">谢谢你的体验!</div>
      <div class="start-btn">
        <img
          class="bg"
          @click="onSkipPageFn('/interview/welcome')"
          src="@/assets/image/start-btn3.png"
        />
        <img
          v-if="interviewId && channel"
          class="bg"
          @click="onSkipPageFn('/interview/report_list')"
          src="@/assets/image/start-btn4.png"
        />
        <img
          v-if="interviewId === 0"
          class="bg"
          @click="onSkipReportPageFn('/interview/tourist_report')"
          src="@/assets/image/start-btn5.png"
        />
      </div>
    </dv-loading>

    <ExitApplication v-if="popUp" @ensureFn="ensureFn" @cancelfn="cancelfn" />
  </div>
</template>

<script>
import ExitApplication from '@/components/ExitApplication.vue'
export default {
  components: {
    ExitApplication //退出应用组件
  },
  data() {
    return {
      popUp: false, //控制退出应用的弹窗
      interviewId: 0, // 0 = 游客 1 = 扫码
      channel: localStorage.getItem('channel')
    }
  },
  created() {
    const _this = this
    if (this.$route.query.id) this.interviewId = parseInt(this.$route.query.id)
    document.onkeydown = function (e) {
      if (e.keyCode === 13 && _this.interviewId === 0)
        _this.onSkipReportPageFn('/interview/tourist_report')
      if (e.keyCode === 13 && _this.interviewId)
        _this.onSkipPageFn('/interview/report_list')
      if (e.keyCode === 27) _this.onOutPageFn()
    }
  },
  methods: {
    // 监听打开退出弹窗
    onOutPageFn() {
      this.popUp = true
    },
    //当用户点击确定退出
    ensureFn() {
      history.go(-history.length + 1)
    },
    //当用户点击取消关闭退出应用的弹窗
    cancelfn(data) {
      this.popUp = data
    },
    // 跳转页面
    onSkipPageFn(url) {
      if (this.channel) {
        this.$router.push(`${url}?channel=${this.channel}`)
      } else {
        this.$router.push(`${url}`)
      }
    },
    // 跳转报告页面
    onSkipReportPageFn(url) {
      this.$router.push(`${url}?channel=${this.channel}`)
    }
  }
}
</script>

<style lang="stylus" scoped>
.end-page-wrapper
  position fixed
  left 0
  top 0
  width 100%
  height 100%
  .loading
    position fixed
    left 0
    top 0
    width 100%
    height 100%
    display flex
    align-items center
    justify-content center
    z-index 999
    .bg
      position absolute
      left 0
      top 0
      width 100%
      height 100%
      display block
      z-index -1
    .loading-text
      font-weight bold
      line-height 100px
      text-align center
      font-size 30px
      opacity 0.8
      color #fff
    .start-btn
      width 100%
      height auto
      display flex
      img
        position relative
        width 200px
        height auto
        display block
        cursor pointer
        margin 0 30px
        z-index 10
</style>
